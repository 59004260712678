/*global angular */

/**
 * The main twitter app module
 *
 * @type {angular.Module}
 */

angular = require('angular');
require('angular-route');
require('angular-ui-bootstrap');
videojs = require('video.js');
require('vjs-video');
require('angular-hashtagify');
MobileDetect = require('mobile-detect');
require('../dist/templateCacheViews');

angular.module('twitter', ['ngRoute','twitterViews','ui.bootstrap','vjs.video','hashtagify'])
	.config(function ($routeProvider,$locationProvider) {
		'use strict';
		$locationProvider.hashPrefix('');
		$locationProvider.html5Mode({
		  enabled: true,
		  requireBase: false
		});
		var routeConfig = {
			controller: 'TwitterCtrl',
			templateUrl: '/views/twitter-index.html'
		};

		$routeProvider
			.when('/', routeConfig)
			.otherwise({
				redirectTo: '/'
			});
	})
	.constant('API_CONFIG', {
	    'url':  'https://api.eyeseetest.com',
			// 'url':  'http://localhost/api/public',
	    'assets':  ''
	})

require('twitterCtrl');
