angular = require('angular');

angular.module('twitter').controller('TwitterCtrl', ['$rootScope','$scope','$http', 'API_CONFIG', '$location', '$window', '$document', '$sce', '$uibModal','$timeout','$interval', function TwitterCtrl($rootScope, $scope, $http, API_CONFIG, $location, $window, $document, $sce, $uibModal, $timeout, $interval) {
		'use strict';
		var globalTime=Date.now();
		var globalEndTime;
		var md = new MobileDetect($window.navigator.userAgent);
		var location = $location.search();
		$scope.location=location;
		$scope.likedTweets = {};
		if(typeof location.desktop ==='undefined' || location.desktop!='true'){
			if (!md.mobile()){
				alert('Only mobile!');
				return;
			}
		}
		else{
			$(':root').addClass('desktop');
			$('body').css('overflow-y','hidden');
		}

		$rootScope.zoomTime={};
		$rootScope.blackTime={};
		$scope.activeView = 'homepage';
		$scope.lastScrollTop={
			'homepage': 0,
			'search': 0
		};
		window.prerolls=[];
		var videoIDS=[];
		var prerollIDS=[];
		var videosTimeout={};

		function getYesterdaysDate() {
			var date = new Date();
			var month = date.toLocaleString('default', { month: 'short' });
      return (date.getDate()==1?1: date.getDate()- 1) + ' ' + month;
		}

		function checkTimeout(activePlayer) {
		 if (!videosTimeout[activePlayer.id].timeout && !videosTimeout[activePlayer.id].inViewport) {
			 videosTimeout[activePlayer.id].inViewport = true;

			 var duration = Math.floor(activePlayer.duration);

			 var parentID = activePlayer.id.slice(0, activePlayer.id.lastIndexOf('_html5'));
			 var parent = $('#' + parentID).parents();
			 var timeoutEl = $(parent).siblings('.timeout')[0];

			 videosTimeout[activePlayer.id].timeout = setTimeout(function(){hideTimeout(activePlayer, timeoutEl)}, 3000);

			 activePlayer.ontimeupdate = function(){
				 var currTime = Math.floor(activePlayer.currentTime);
				 var remainingTime = duration - currTime;
				 var remainingTimeFormatted = remainingTime > 9 ? remainingTime: '0'+ remainingTime;

				 $(timeoutEl).html('0:' + remainingTimeFormatted);
				 $(timeoutEl).css("display", "block");
			 }
			}
		}

		 function hideTimeout(activePlayer, timeoutEl) {
			 activePlayer.ontimeupdate = null;
			 videosTimeout[activePlayer.id].timeout = false;
			 $(timeoutEl).css("display", "none");
			 window.clearTimeout(videosTimeout[activePlayer.id].timeout);
		 }

		 function resetTimeout(player) {
			 player.ontimeupdate = null;
			 videosTimeout[player.id].timeout = false;
			 videosTimeout[player.id].inViewport = false;
		 }

		 function initLikeGIF (tweet) {
			 if (tweet.mulanLike == true) return 'mulanlike';
			 if (tweet.appleLike == true) return 'applelike';
			 return 'like';
		 }

		 function getFakeTweet(tweetType) {
			 return {
				 type: tweetType,
				 id: Math.floor(Math.random()*5000),
				 idIndex: 100,
				 id: 232,
				 index: 4560,
				 updated_at: "2016-12-31 23:00:00",
				 created: "2016-12-31 23:00:00",
				 userID: 7,
				 name: "2018060_cell1_TL2_post1_Retweeted1",
				 v2: false,
				 data: {
					externalText: "External text Biden visits Florida to shore up support among critical Hispanic voters http://reut.rs/35zx37D",
					externalUrl: "www.someurl.com",
				  externalVideoUrl: 'https://cdn.videvo.net/videvo_files/video/premium/video0121/small_watermarked/25%20Alpen%20Gold%20day%204_preview.webm',
					edgeView: '0',
					imageUrl: "",
					likeNumber: "32",
					videoUrl: '',
					profileImage: "http://data.eyeseetest.com/20202162/cell1/tl1/tw18/pk7FyTvS_normal.jpg",
					profileName: "Laura Mock",
					profileNameDate: getYesterdaysDate(),
					profileNameDateHours: "11:25 PM",
					profileNameLink: "IngrahamAngle",
					promoted: true,
					promotedText: "Promoted ",
					replyNumber: "18",
					retweeted: true,
					retweetNumber: "11",
					retweetedText: "Some_user Retweeted:",
					retweetedData: {
						imageUrl: 'https://pbs.twimg.com/media/Eh9CEgtXgAEsdQ2?format=jpg&name=small',
						videoUrl: '',
						// imageUrl: '',
						// videoUrl: 'https://cdn.videvo.net/videvo_files/video/premium/video0121/small_watermarked/25%20Alpen%20Gold%20day%204_preview.webm',
						text: 'Biden visits Florida to shore up support among critical Hispanic voters http://reut.rs/35zx37D',
						profileImage: 'https://pbs.twimg.com/profile_images/1194751949821939712/3VBu4_Sa_normal.jpg',
						profileName: 'Reuters',
						profileNameLink: 'Reuters',
						profileNameDate: getYesterdaysDate(),
						profileNameDateHours: '9:30AM',
						autoplay: true,
						idIndex: Math.floor(Math.random() * 7158),
						view_count: 758
					},
					text: "“Vote for me, and I’ll make sure  Florida runs just like Cuba!”",
					trusted: true,
					appName: 'BestApp',
					appStars: 4.5,				
					appReviews: '42k reviwers', 
					appFree: 'Free',
					appCategory: 'Mock',
					appImageUrl: 'https://api.lorem.space/image/album?w=150&h=150',
					appButton: 'install',
					appType: true
				}
			 }
		 }

		$scope.$on('$viewContentLoaded', function(){
			if(location.explore){
				$timeout(function() {
			    $('#searchZoomID').trigger('click');
			  },100);
			}
			if(location.landing){
				$timeout(function() {
			    $('#searchZoomID').trigger('click');
					$timeout(function() {
				    $('#searchVideoContainer').trigger('click');
				  },100);
			  },100);

			}
	  });

		function hasParameter(param) {
			var params = new URLSearchParams(window.location.search);
			return params.get(param);
		}

		$http({
			method: 'GET',
      url: API_CONFIG.url + "/twitter/timeline?"+'id='+location.timeline,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
    .then(function(data, status){
			if(data.data.id){
				$scope.timelineMenuImage=data.data.menuImage!=''?data.data.menuImage:'';
				$scope.nextPage=data.data.nextPage;
				$scope.edgeView=data.data.edgeView;
				$scope.tweets=angular.fromJson(data.data.posts);
				// $scope.tweets.unshift(getFakeTweet('external'));
				// $scope.tweets.unshift(getFakeTweet('retweeted'));

				if(data.data.explore){
					$scope.explore=angular.fromJson(data.data.explore.data);
					// var topics = ['Politics', 'Sport', 'Entertainment', 'Education', 'Culture'];
					// var context = '@BTS_twt earns their record-extending 23rd No. 1 hit on the #WorldDigitalSongSales chart this week, with "Filter."';
					// for (var i = 1; i <= 5; i++) {
					// 	// console.log('EXPLORE ', $scope.exploreTweets[i])
					// 	$scope.explore['trendTopic'+i] = topics[Math.floor(Math.random() * topics.length)];
					// 	if (i % 2 === 0)
					// 		$scope.explore['trendContext'+i] = context;
					// }
				}
				if(data.data.explorePosts){
					$scope.exlopreTweets=angular.fromJson(data.data.explorePosts);
				}
				if(data.data.adsPosts){
					$scope.adsPosts=angular.fromJson(data.data.adsPosts);
					console.log($scope.adsPosts);
				}

				var i=0;
				var allTweets=[];
				angular.forEach($scope.tweets, function(tweet,index){
						tweet.data = angular.fromJson(tweet.data);
						tweet.data.type = tweet.type;
						console.log('[vp]tweet.data',tweet.data);
						tweet.data.profileNameDate = getYesterdaysDate();

						if (tweet.data.retweetedData && tweet.data.retweetedData.videoUrl) {
							videoIDS.push(tweet.data.retweetedData.videoUrl);
						}
						if(tweet.data.retweetedData){
							tweet.data.retweetedData.profileNameDate = getYesterdaysDate();
						}

						if(tweet.data.videoUrl){
							videoIDS.push(tweet.data.videoUrl);
						}
						if(tweet.data.orgVideoUrl){
							prerollIDS[index]=(tweet.data);
						}
						if (tweet.type == 'external' && tweet.data.externalVideoUrl) {
							videoIDS.push(tweet.data.externalVideoUrl);
						}
						tweet.data = angular.fromJson(tweet.data);
						allTweets.push({ selector: '#'+tweet.name+i ,name: tweet.name+i});
						i++;
				});
				angular.forEach($scope.exlopreTweets, function(tweet){
						console.log('[vp]tweet.data',tweet);
						tweet.data = angular.fromJson(tweet.data);
						if(tweet.data.videoUrl){
							videoIDS.push(tweet.data.videoUrl);
						}
						tweet.data = angular.fromJson(tweet.data);
						allTweets.push({ selector: '#'+tweet.name+i ,name: tweet.name+i});
						i++;
				});
				angular.forEach($scope.adsPosts, function(tweet){
						console.log('[vp]tweet.data',tweet);
						tweet.data = angular.fromJson(tweet.data);
						if(tweet.data.videoUrl){
							videoIDS.push(tweet.data.videoUrl);
						}
						tweet.data = angular.fromJson(tweet.data);
						allTweets.push({ selector: '#'+tweet.name+i ,name: tweet.name+i});
						i++;
				});
				$timeout($scope.setCounters, 2000, false, allTweets);
			}
			else{
				alert('No active');
			}
    }
    ,function(data, status){
       alert('No timeline');
    });

		$scope.updateTime =function () {
      $rootScope.currTime+=0.1;
			$rootScope.currTime=Math.round($rootScope.currTime * 100) / 100;
  	}

    $scope.tagUserClick = function(e) {
        var tagText = e.target.innerText;
    };

    $scope.tagTermClick = function(e) {
        var tagText = e.target.innerText;
    };

    $scope.trustHtml = function(html) {
        return $sce.trustAsHtml(html);
    };
		var searchVideo=null;
		var videosReady=0;
		$scope.$on('vjsVideoReady', function (e, data) {
				// console.log('video:', data);
        console.log('video id:' + data.id);
        // console.log('video.js player instance:' + data.player);
				if(data.id.split("_")[0]=='searchVideo'){
					data.player.src({ type: "video/mp4", src: $scope.explore.videoUrl});
					data.player.mute=true;
					data.player.autoplay=true;
					searchVideo=data.player;
					//data.player.play();
				}
				else{
					var videoSrc=videoIDS[videosReady];
					data.player.src({ type: "video/mp4", src: videoSrc});
					videosReady++;
				}
				var videoID=data.id.split("_")[1];
				console.log('ID:' + videoID);

				$timeout(function(playerr){
					var playerEl = $('#'+data.id)[0];
					videosTimeout[data.id] = {timeout: false, inViewport: false};
					if (playerr.is(":in-viewport(0)") && playerr.attr( "autoplay" )) {
						playerr[0].player.currentTime(0);
						playerr[0].player.play();
						if (data.id.split("_")[0] != 'preroll')
							checkTimeout(playerEl);
					}
					else{
						playerr[0].player.currentTime(0);
						playerr[0].player.pause();
						if (data.id.split("_")[0] != 'preroll')
							resetTimeout(playerEl);
					}
				}, 2000, false, $(data.player.el()));

    });

		function setBottomDimensions() {
			var $frame = $('.frame-bottom');
			var $bottom=$('.header-icons-desktop');
			$bottom.css("top",($frame.offset().top-43)+"px");
		}

		$(document).ready(function(){

			var lastScrollTop = 0;

			$(window).click(function(event){
				if($(event.target).hasClass('btnForPlay'))return;
				var objToSend={};
				objToSend.type='click';
				objToSend.x=event.clientX;
				if(typeof location.desktop ==='undefined' || location.desktop!='true'){
					objToSend.y= $(this).scrollTop()+event.clientY;
				}
				else{
					objToSend.y= $('.twitter-content').scrollTop()+event.clientY;
				}

				// console.log('click',objToSend);
				window.parent.postMessage(JSON.stringify(objToSend),"*");
			});

			if(typeof location.desktop ==='undefined' || location.desktop!='true'){
				$(window).scroll(function(event){
					console.log("--scroll--");
					 var st = $(this).scrollTop();
					 if (st > lastScrollTop){
						 $("header").css({ top: '-43px' });
					 }
					 else {
						 $("header").css({ top: '0px' });
					 }
					 lastScrollTop = st;
					 $scope.lastScrollTop[$scope.activeView] = st;
					 var objToSend={};
 					 objToSend.type='scroll';
 					 objToSend.top=st;
					 // console.log('scroll',objToSend);
 					 window.parent.postMessage(JSON.stringify(objToSend),"*");
				});
			}
			else{
				$('.twitter-content').scroll(function(event){
					console.log("--scroll--");
					 var st = $(this).scrollTop();
					 var top = hasParameter('explore') ? (50-st)+'px' : '7px';
					 if (st > lastScrollTop){
						 $("header").css({ top:top });
					 }
					 else {
						 $("header").css({ top: '50px' });
						}
					 lastScrollTop = st;
					 var objToSend={};
 					 objToSend.type='scroll';
 					 objToSend.top=st;
					 // console.log('scroll',objToSend);
 					 window.parent.postMessage(JSON.stringify(objToSend),"*");
					 $scope.lastScrollTop[$scope.activeView] = st;
				});
			}


			// $('#timeline-container').css('marginTop', $('header').outerHeight(true)+10 );
			//


			var activePlayer;
			if(typeof location.desktop ==='undefined' || location.desktop!='true'){
				$(window).scroll(function() {
			    $('.video-js video').each(function(){
					
			      if ($(this).is(":in-viewport(0)")) {
			        activePlayer=$(this)[0];
							if($(this).attr( "autoplay" )){
								$('.btnForPlay').trigger('click');
				        $('.btnForPlay').trigger('touchstart');
								$(this)[0].play();
								if (this.id.split('_')[0] !== 'preroll')
									checkTimeout(activePlayer);
							}
			      }
			      else {
			        $(this)[0].pause();
							$(this)[0].muted=true;
							if (this.id.split('_')[0] !== 'preroll')
								resetTimeout($(this)[0]);
			      }
			    })
					$('div[id^=preroll_][id$=_image]').each(function(){
			      if ($(this).is(":in-viewport(0)")) {
							var $this = $(this);
							setTimeout(function() {
								$this.hide();
								$('#'+$this[0].id.replace('_image','_text')).show();
								$('#'+$this[0].id.replace('_image',''))[0].style.display="block";
								$('#'+$this[0].id.replace('_image',''))[0].firstChild.play();
								// console.log($('#'+$this[0].id.replace('_image','')));
					    }, 1000);
			      }
			    })
			  });
			}
			else{
				$('.twitter-content').scroll(function() {
			    $('.video-js video').each(function(){
			      if ($(this).is(":in-viewport(0)")) {
			        activePlayer=$(this)[0];
							if($(this).attr( "autoplay" )){
								$('.btnForPlay').trigger('click');
				        $('.btnForPlay').trigger('touchstart');
								$(this)[0].play();
								checkTimeout(activePlayer);
							}
			      }
			      else {
			        $(this)[0].pause();
							$(this)[0].muted=true;
							resetTimeout($(this)[0]);
			      }
			    })
				});
			}

		  $('.btnForPlay').click(function() {
		      activePlayer.play();
		      //activePlayer.muted=true;
		    }
		  );
		  $('.btnForPlay').on('touchstart',function() {
		      activePlayer.play();
		      //activePlayer.muted=false;
		    }
		  );
		});
		$scope.setHeaderHeightSerach=function(height){
			 $('#timeline-container').css('marginTop', $('header').outerHeight(true)+height );
			 if(height==0){
				 if (searchVideo)searchVideo.play();
				 	$scope.scrollPos=$('html,body').scrollTop();
				 	$('html,body').scrollTop(0);
			 }
			 else{
			 	if (searchVideo)searchVideo.pause();
					$('html,body').scrollTop($scope.scrollPos);
			 }
		}

		$scope.prerollVideos=[];
		$scope.setPrerolls=function(){
			$('video[id^=preroll_]').each(function(){
				console.log($(this)[0].id);
				var index = $(this)[0].id.split("_");
				index=index[1];
				videojs($(this)[0].id, {}, function(){
				  var player = this;
					player.src({
					  type: 'video/mp4',
					  src: prerollIDS[index].orgVideoUrl
					});
				  player.preroll({
				    // src:"https://cdn.videvo.net/videvo_files/video/free/2021-04/small_watermarked/210329_06B_Bali_1080p_013_preview.webm",
				    src:prerollIDS[index].prerollUrl,
						skipTime:prerollIDS[index].skipTime,
						design:prerollIDS[index].design,
						skipImage:prerollIDS[index].prerollSkipImage,
						brand:prerollIDS[index].prerollBrand,
						callback:function(playerr){
							$scope.prerollVideos[playerr.id()]=playerr;
							// console.log($scope.prerollVideos);
							$timeout(function(){
								$(window).scroll();
								playerr.currentTime(0);
								if($('video[id^=preroll_]').length==Object.keys($scope.prerollVideos).length){
									Object.keys($scope.prerollVideos).forEach(function (item) {
										if (!$($scope.prerollVideos[item].el()).is(":in-viewport(0)")) {
											$(window).scroll();
											$scope.prerollVideos[item].currentTime(0);
											// alert($scope.prerollVideos[item].id());
										}
									});
								}
							}, 500);
						}
				  })
					if(prerollIDS[index].design=="4"){
						$("#"+$(this)[0].id()+"_text").hide();player.el().style.display="none";
					}

				});
			});

		}
		$('body').hide();
		$scope.setCounters=function(allTweets){
			$('body').show();
			$scope.setPrerolls();
			console.log(allTweets);
			allTweets.push({ selector: '#searchVideoContainer' ,name: 'searchVideoContainer'});
			allTweets.push({ selector: '#searchVideoTrendingContainer' ,name: 'searchVideoTrendingContainer'});
			allTweets.push({ selector: '#trending1' ,name: 'trending1'});
			allTweets.push({ selector: '#trending2' ,name: 'trending2'});
			allTweets.push({ selector: '#trending3' ,name: 'trending3'});
			allTweets.push({ selector: '#trending4' ,name: 'trending4'});
			allTweets.push({ selector: '#trending5' ,name: 'trending5'});

			const pageWrapper = document.querySelector("#page-body");
			console.log("🚀 ~ file: twitterCtrl.js ~ line 522 ~ TwitterCtrl ~ pageWrapper", pageWrapper.getBoundingClientRect())
			window.parent.postMessage(JSON.stringify({
				clientRect:  pageWrapper.getBoundingClientRect(),
			}),"*");

			
			$timeout(function(allTweets){
				$.counter({
					fields: allTweets,
					reportInterval : 5,
					percentOnScreen : "90%",
					callback: function(data,log) {
						angular.forEach(log, function(value,key){
							if(key.indexOf('-startTime')==-1){
								if(value==0.3){
									log[key+'-startTime']=$rootScope.currTime-value;
									log[key+'-startTime']=Math.round(log[key+'-startTime'] * 100) / 100;
								}
								if(value>0.3 && !log.hasOwnProperty(key+'-startTime')){
									log[key+'-startTime']=$rootScope.currTime-value;
									log[key+'-startTime']=Math.round(log[key+'-startTime'] * 100) / 100;
								}
							}
						})
						$scope.statistics=log;
						// console.log(log,$rootScope.currTime,$rootScope.zoomTime,$rootScope.blackTime);
					}
				});
			}, 0, false, allTweets);
			globalEndTime=(Date.now()-globalTime)/1000;
			$rootScope.currTime=0.1;
			$interval($scope.updateTime, 100);
			if(typeof location.desktop !=='undefined' && location.desktop=='true'){
				setBottomDimensions();
			}
		}

		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());
		$window.history.pushState(null, 'any', $location.absUrl());

	  $scope.openBlackModal = function (tweet,type) {
			if(typeof location.desktop !=='undefined' && location.desktop=='true'){
				return;
			}
			var blackModal=$uibModal.open({
	      templateUrl: '/views/partials/modalBlack.html',
	      controller:  ['$scope',function($scope) {
					$.counter.pause();
	        $scope.tweet = tweet;
					$scope.type=type;
					$rootScope.blackTime[$scope.tweet.name+$scope.tweet.idIndex+'-black-start']?'':$rootScope.blackTime[$scope.tweet.name+$scope.tweet.idIndex+'-black-start']=$rootScope.currTime;
					var blackTime=0;
					$scope.updateBlackTime =function () {
						blackTime+=0.1;
						blackTime=Math.round(blackTime * 100) / 100;
						console.log('-------',blackTime);
					}
					var updateBlackTimeInterval = $interval($scope.updateBlackTime, 100);

					$scope.$on('vjsVideoReady', function (e, data) {
							data.player.src({ type: "video/mp4", src: tweet.videoUrl});
	        });
					$scope.closeModal = function () {
						$rootScope.blackTime[$scope.tweet.name+$scope.tweet.idIndex+'-black']?$rootScope.blackTime[$scope.tweet.name+$scope.tweet.idIndex+'-black']+=blackTime : $rootScope.blackTime[$scope.tweet.name+$scope.tweet.idIndex+'-black']=blackTime;
			      blackModal.dismiss({$value: 'cancel'});
						$.counter.continue();
						$interval.cancel(updateBlackTimeInterval);
			    };
					blackModal.result.then(function () {}, function () {});
	      }]
	    });
			blackModal.result.then(function () {}, function () {});
	  };

		$scope.openZoomModal = function (tweet) {
			if(typeof location.desktop !=='undefined' && location.desktop=='true'){
				return;
			}
			var zoomModal=$uibModal.open({
	      templateUrl: '/views/partials/modalZoom.html',
	      controller:  ['$rootScope','$scope','$sce','$interval',function($rootScope,$scope,$sce,$interval) {
					$.counter.pause();
	        $scope.tweet = tweet;
					$rootScope.zoomTime[$scope.tweet.name+$scope.tweet.idIndex+'-zoom-start']?'':$rootScope.zoomTime[$scope.tweet.name+$scope.tweet.idIndex+'-zoom-start']=$rootScope.currTime;
					var zoomTime=0;
					$scope.updateZoomTime =function () {
			      zoomTime+=0.1;
						zoomTime=Math.round(zoomTime * 100) / 100;
						console.log('-------',zoomTime);
			  	}
					var updateZoomTimeInterval = $interval($scope.updateZoomTime, 100);


					$scope.$on('vjsVideoReady', function (e, data) {
							data.player.src({ type: "video/mp4", src: tweet.videoUrl});
	        });
					$scope.closeModal = function () {
						$rootScope.zoomTime[$scope.tweet.name+$scope.tweet.idIndex+'-zoom']?$rootScope.zoomTime[$scope.tweet.name+$scope.tweet.idIndex+'-zoom']+=zoomTime : $rootScope.zoomTime[$scope.tweet.name+$scope.tweet.idIndex+'-zoom']=zoomTime;
			      zoomModal.dismiss({$value: 'cancel'});
						$.counter.continue();
						$interval.cancel(updateZoomTimeInterval);
			    };
					zoomModal.result.then(function () {}, function () {});

					$scope.tagUserClick = function(e) {
			        var tagText = e.target.innerText;
			    };
			    $scope.tagTermClick = function(e) {
			        var tagText = e.target.innerText;
			    };
			    $scope.trustHtml = function(html) {
			        return $sce.trustAsHtml(html);
			    };

					$scope.openBlackModal = function (tweet,type) {
						var blackModal=$uibModal.open({
				      templateUrl: '/views/partials/modalBlack.html',
				      controller: ['$scope',function($scope) {
				        $scope.tweet = tweet;
								$scope.type=type;
								$scope.$on('vjsVideoReady', function (e, data) {
										data.player.src({ type: "video/mp4", src: tweet.videoUrl});
				        });
								$scope.closeModal = function () {
						      blackModal.dismiss({$value: 'cancel'});
						    };
								blackModal.result.then(function () {}, function () {});
				      }]
				    });
						blackModal.result.then(function () {}, function () {});
				  };
	      }]
	    });
			zoomModal.result.then(function () {}, function () {});
	  };

		$scope.gotoNextPage = function() {
			angular.forEach($rootScope.blackTime, function(value,name){
					$scope.statistics[name]=value;
			});
			angular.forEach($rootScope.zoomTime, function(value,name){
					$scope.statistics[name]=value;
			});
			console.log($scope.prerollVideos);
			Object.keys($scope.prerollVideos).forEach(function (item) {
				if(!window.prerolls.hasOwnProperty(item)){
					window.prerolls[item]=$scope.prerollVideos[item].currentTime();
				}
			});
			Object.keys(window.prerolls).forEach(function (item) {
				$scope.statistics[item]=window.prerolls[item];
			});
			console.log('-----------------',$scope.statistics);
			var dataForSending={id:location.timeline,statistics:$scope.statistics,totalTime:$rootScope.currTime,diffTime:globalEndTime,likes:$scope.likedTweets,fleets:fleetsData};
			$http({
	      method: 'POST',
	      url: API_CONFIG.url + "/tests/twitter/save",
	      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
	      data: 'data='+angular.toJson(dataForSending)+'&id='+location.id+'&userid='+location.userid+'&timeline='+location.timeline
	    })
	    .then(function(data, status){
				if(data.data=='eyesee_end_task'){
					window.parent.postMessage('eyesee_end_task',"*");
				}
				else if(typeof location.pt ==='undefined' || location.pt!='true'){
					$window.location.href = data.data;
				}
				else{
					window.videoId="finish";
        	window.innerVideoTime="1";
				}
	    }
	    ,function(data, status){
	       alert('Error');
	    });
    };

		$scope.playLikeGif = function (tweet,target) {
			if(typeof location.desktop ==='undefined' || location.desktop!='true') return;

			if(!$scope.likedTweets[tweet.name]){
				$scope.likedTweets[tweet.name] = {gif: initLikeGIF(tweet),likes:0,dislikes:0};
			}

			$scope.likedTweets[tweet.name].isLiked = !$scope.likedTweets[tweet.name].isLiked;

			if($scope.likedTweets[tweet.name].isLiked){
				$scope.likedTweets[tweet.name].likes+=1;
			}
			if(!$scope.likedTweets[tweet.name].isLiked){
				$scope.likedTweets[tweet.name].dislikes+=1;
			}
			// if(!$scope.likedTweets[tweet.name].time){
			// 	$scope.likedTweets[tweet.name].time = $rootScope.currTime;
			// }

			if(tweet.appleLike==true){
				$scope.likedTweets[tweet.name].gif = $scope.likedTweets[tweet.name].isLiked ? 'applelike' : '';
			}

			if(tweet.mulanLike==true){
				$scope.likedTweets[tweet.name].gif = $scope.likedTweets[tweet.name].isLiked ? 'mulanlike': '';
			}
			setTimeout(function(){$(target).find('img').attr('src',$scope.likedTweets[tweet.name].gif+'.gif?'+Math.random()*5000)},20);

		};

		$scope.setActiveView = function (activeView) {
			$scope.activeView = activeView;
			var scrollY = $scope.lastScrollTop[activeView];
			if(typeof location.desktop ==='undefined' || location.desktop!='true'){
				$(window)[0].scrollTo(0, scrollY);
			} else {
				$(".twitter-content")[0].scrollTo(0, scrollY);
			}
		}

		const FLEETS_GAP = 20;
		var initialTouchX = 0;
		var lastTouchX = 0;
		var videoDimensions = {
			width: 0,
			height: 0
		};
		var currentFleetData = null;
		var fleetsData = [];
		var touchStartTimestamp = 0;
		var activeSubFleetIdx = 0;
		var pauseTimeoutID = null;
		var areElementsPositioned = false;
		$scope.isShowingFleets = false;
		$scope.activeIdx = -1;
		$scope.muted = true;
		$scope.fleets = [];

		if(location.fleets){
			$http.get(location.cell+'.json')
			.then(function(data) {
				$scope.fleets = data.data;
				listenToLearnMoreClick();
				listenToExitFleets();
			});
		}


		function calcVideoDimensions() {
			var videos = document.querySelectorAll('.fleet-media video');
			const RATIO = 9 / 16;
			var height = window.innerHeight;
			var width = height * RATIO;

			if (width > window.innerWidth) {
				width = window.innerWidth;
				height = width / RATIO;
			}

			videoDimensions.width = width;
			videoDimensions.height = height;

			for (var i = 0; i < videos.length; i++) {
				videos[i].style.width = width + 'px';
				videos[i].style.height = height + 'px';
			}
		}

		function positionExitFleetEl() {
			var el = document.getElementById('exit-fleets');
			var videoEl = document.querySelectorAll('.fleet-media video')[$scope.activeIdx].getBoundingClientRect();
			el.style.right = (videoEl.left + 37.5) + 'px';
			el.style.width = 30 + 'px';
			el.style.height = 30 +'px';
			el.style.top =  (videoEl.top + 37.5) + 'px';
		};

		function positionLearnMoreEl() {
			var el = document.getElementById('learn-more');
			var videoEl = document.querySelectorAll('.fleet-media video')[$scope.activeIdx].getBoundingClientRect();
			el.style.left = ((videoEl.width + videoEl.left)/2  - 62.5) + 'px';
			el.style.width = 125 + 'px';
			el.style.height = 60 +'px';
			el.style.bottom =  (window.innerHeight - videoEl.bottom + 40) + 'px';
		};

		function adjustElemsToViewport() {
			calcVideoDimensions();
			listenToPositionElements();
		};

		$scope.viewFleet = function(e, index) {
			document.body.style.overflow='hidden';
			$.counter.pause();
			$scope.isShowingFleets = true;
			$scope.activeIdx = index;
			adjustElemsToViewport();
		  resetFleetsPosition($scope.fleets, index);
		  listenToTouchEvts();
			handleFleetStart(e);
		};

		function exitFleets(e) {
			document.body.style.overflow='auto';
			e.stopPropagation();
			e.preventDefault();
			checkSubfleets(e);
			pauseVideo($scope.activeIdx);
			resetAllVideos();
			handleFleetEnd(e);
			console.log('FD: ', fleetsData)
			$scope.isShowingFleets = false;
			$scope.activeIdx = 0;
			activeSubFleetIdx=0;
			resetFleetsPosition($scope.fleets, 0);
			removeTouchEvtsListeners();
			$.counter.continue();
		};

		function resetAllVideos() {
			var videos = document.querySelectorAll('.fleet-media video');
			for (var i = 0; i < videos.length; i++) {
				videos[i].currentTime= 0;
			}
		};

		function listenToPositionElements() {
			var videos = document.querySelectorAll('.fleet-media video');
			for (var i = 0; i < videos.length; i++) {
				videos[i].onplay= function() {
					if (!areElementsPositioned) {
						positionExitFleetEl();
						positionLearnMoreEl();
						areElementsPositioned = true;
					}
				};
			}
		};

		function handleFleetStart(e) {
			initCurrentFleetData(e, $scope.fleets[$scope.activeIdx].id + '_subfleet' + (activeSubFleetIdx + 1));
			playVideo($scope.activeIdx);
		};

		function onTouchStart(e) {
			e.preventDefault();
			touchStartTimestamp = e.timeStamp;
			initialTouchX = e.touches[0].pageX;
			lastTouchX = e.touches[0].pageX;
			this.addEventListener('touchmove', onTouchMove);
			this.addEventListener('touchend', onTouchEnd);
			var self = this;
			pauseTimeoutID = window.setTimeout(function () {
				self.removeEventListener('touchmove', onTouchMove);
				self.removeEventListener('touchend', onTouchEnd);
				pauseFleets(self);
			}, 1000);
		};

		function onTouchMove(e) {
			e.preventDefault();
			window.clearTimeout(pauseTimeoutID);
			for (var i = 0; i < $scope.fleets.length; i++) {
				$scope.fleets[i].currentPosition += e.touches[0].pageX - lastTouchX;
			};
			lastTouchX = e.touches[0].pageX;
		};

		function checkSeenStatus() {
			var subfleetIdx = detectSubfleet(document.querySelectorAll('.fleet-media video')[$scope.activeIdx].currentTime);
			if ($scope.fleets[$scope.activeIdx].subfleets.length == subfleetIdx + 1)
				$scope.fleets[$scope.activeIdx].seen = true;
		}

		function onTouchEnd(e) {
			window.clearTimeout(pauseTimeoutID);
			var touchTimeDiff = e.timeStamp - touchStartTimestamp;
			touchStartTimestamp = 0;
			checkSeenStatus();
			if (touchTimeDiff < 500) {
				pauseVideo($scope.activeIdx);
				navigateFast(e);
				initialTouchX = 0;
				return;
			};

			if ($scope.fleets[$scope.activeIdx].currentPosition <= -1*(window.innerWidth / 2)) {
				// console.log('SHOW NEXT');
				pauseVideo($scope.activeIdx);
				showNextFleet(e);
			} else if ($scope.fleets[$scope.activeIdx].currentPosition <= (window.innerWidth / 2)) {
				// console.log('DONT SWIPE');
				resetFleetsPosition($scope.fleets, $scope.activeIdx);
			} else {
				// console.log("SHOW PREVIOUS");
				pauseVideo($scope.activeIdx);
				showPreviousFleet(e);
			};
			this.removeEventListener('touchmove', onTouchMove);
			this.removeEventListener('touchend', onTouchEnd);
		};

		function navigateFast(e) {
			const TAP_RADIUS = 10;
			if (e.changedTouches[0].pageX === initialTouchX || Math.abs(e.changedTouches[0].pageX - initialTouchX) <= TAP_RADIUS) {
				// console.log("TAP - change subfleet!")
				showSubFleet(e); // user has just tapped on the screen
			} else {
				changeFleet(e); // user has swiped fast
			}
		};

		function changeFleet(e) {
			if (initialTouchX < e.changedTouches[0].pageX ) {
				// console.log("PREV FLEET")
				showPreviousFleet(e);
			} else {
				// console.log("NEXT FLEET")
				showNextFleet(e);
			};
		};

		function showPreviousFleet(e) {
			if ($scope.activeIdx - 1 < 0) {
				$scope.activeIdx = 0;
				exitFleets(e);
				return;
			};
			checkSubfleets(e);
			$scope.activeIdx--;
			for (var i = 0; i < $scope.fleets.length; i++) {
				var newPosition = $scope.fleets[i].initialPosition + window.innerWidth + FLEETS_GAP;
				$scope.fleets[i].currentPosition = newPosition;
				$scope.fleets[i].initialPosition = newPosition;
			};
			handleFleetEnd(e);
			updateVideoTime($scope.activeIdx, 0);
			handleFleetStart(e);
		};

		function showNextFleet(e, skipCheck) {
			activeSubFleetIdx = 0;
			if ($scope.activeIdx + 1 === $scope.fleets.length) {
				$scope.activeIdx = 0;
				exitFleets(e);
				return;
			}
			// if respondent didn't change subfleet manually, it's not registered!
			if (!skipCheck)
				checkSubfleets(e);
			$scope.activeIdx++;
			for (var i = 0; i < $scope.fleets.length; i++) {
				var newPosition = $scope.fleets[i].initialPosition - window.innerWidth - FLEETS_GAP;
				$scope.fleets[i].currentPosition = newPosition;
				$scope.fleets[i].initialPosition = newPosition;
			};
			handleFleetEnd(e);
			updateVideoTime($scope.activeIdx, 0);
			handleFleetStart(e);
		};

		function showSubFleet(e) {
			if (e.changedTouches[0].pageX <= 1/3*window.innerWidth) {
				showPreviousSubFleet(e);
			} else {
				showNextSubFleet(e);
			};
		};

		function detectSubfleet(currentTime) {
			var subfleetIdx = 0;
			for (var i = 0; i < $scope.fleets[$scope.activeIdx].subfleets.length; i++) {
				if (currentTime < $scope.fleets[$scope.activeIdx].subfleets[i]) {
					return subfleetIdx;
				}
				subfleetIdx = i;
			}
			return subfleetIdx;
		};

		function showPreviousSubFleet(e) {
			var currentTime = document.querySelectorAll('.fleet-media video')[$scope.activeIdx].currentTime;
			var subfleetIdx = detectSubfleet(currentTime)
			subfleetIdx--;
			if (subfleetIdx < 0) {
				activeSubFleetIdx = 0;
				showPreviousFleet(e);
				return;
			};
			handleFleetEnd(e);
			activeSubFleetIdx = subfleetIdx;
			updateVideoTime($scope.activeIdx, $scope.fleets[$scope.activeIdx].subfleets[subfleetIdx]);
			handleFleetStart(e);
		};

		function showNextSubFleet(e) {
			var currentTime = document.querySelectorAll('.fleet-media video')[$scope.activeIdx].currentTime;
			var subfleetIdx = detectSubfleet(currentTime)
			subfleetIdx++;
			if (subfleetIdx >= $scope.fleets[$scope.activeIdx].subfleets.length) {
				activeSubFleetIdx = 0;
				showNextFleet(e, true);
				return;
			};
			handleFleetEnd(e);
			activeSubFleetIdx = subfleetIdx;
			updateVideoTime($scope.activeIdx, $scope.fleets[$scope.activeIdx].subfleets[subfleetIdx]);
			handleFleetStart(e);
		};

		function resetFleetsPosition(fleets, activeIdx) {
			for (var i = 0; i < fleets.length; i++) {
				var initialPosition = (i - activeIdx) * (window.innerWidth + FLEETS_GAP);
				fleets[i].initialPosition = initialPosition;
				fleets[i].currentPosition = initialPosition;
			};
		};

	  function clickedLearnMore() {
			currentFleetData.isLearnMoreClicked = true;
		};

		function checkSubfleets(e) {
			checkSeenStatus();
			var subfleetIdx = detectSubfleet(document.querySelectorAll('.fleet-media video')[$scope.activeIdx].currentTime);
			// if respondent didn't change subfleet manually, it's not registered!
			if (subfleetIdx !== activeSubFleetIdx) {
				var endTime = currentFleetData.startTime + $scope.fleets[$scope.activeIdx].subfleets[subfleetIdx] * 1000;
				currentFleetData.endTime = endTime;
				currentFleetData.timeSpent = Math.round((currentFleetData.endTime - currentFleetData.startTime)* 100)  / 100000;
				updateFleetsData(e);
				initCurrentFleetData(e, $scope.fleets[$scope.activeIdx].id + '_subfleet' + (activeSubFleetIdx + 1));
				currentFleetData.startTime = endTime;
				currentFleetData.endTime = e.timeStamp;
			};
		};

		$scope.onVideoEnded = function(e) {
			// checkSubfleets(e);
			pauseVideo($scope.activeIdx);
			showNextFleet(e);
		}

		function updateVideoTime(idx, time) {
			document.querySelectorAll('.fleet-media video')[idx].currentTime = time;
		}

		function pauseVideo(idx) {
			document.querySelectorAll('.fleet-media video')[idx].pause();
		};

		function playVideo(idx) {
			document.querySelectorAll('.fleet-media video')[idx].play();
		};

		function pauseFleets(el) {
			pauseVideo($scope.activeIdx);
			el.addEventListener('touchend', resumeFleets);
		};

		function resumeFleets() {
			window.clearTimeout(pauseTimeoutID);
			this.removeEventListener('touchend', resumeFleets);
			playVideo($scope.activeIdx);
		};

		function handleFleetEnd(e) {
			// $scope.fleets[$scope.activeIdx].seen = true;
			currentFleetData.endTime = e.timeStamp;
			currentFleetData.timeSpent = Math.round((currentFleetData.endTime - currentFleetData.startTime)* 100)  / 100000;
			updateFleetsData(e);
		};

		function initCurrentFleetData(e, id) {
			currentFleetData = {
				id: id,
				startTime: e.timeStamp,
				endTime: null,
				timeSpent: 0,
				isLearnMoreClicked: false
			};
		};

		function updateFleetsData() {
			currentFleetData.startTime=Number((currentFleetData.startTime/1000).toFixed(1));
			currentFleetData.endTime=Number((currentFleetData.endTime/1000).toFixed(1));
			currentFleetData.timeSpent=Number((currentFleetData.timeSpent).toFixed(1));
			fleetsData.push(currentFleetData);
		};

		function listenToTouchEvts() {
			var fleetsEl = document.querySelectorAll('.fleet-media');
			for (var i = 0; i < fleetsEl.length; i++) {
				fleetsEl[i].addEventListener('touchstart', onTouchStart);
			};
		};

		function removeTouchEvtsListeners() {
			var fleetsEl = document.querySelectorAll('.fleet-media');
			for (var i = 0; i < fleetsEl.length; i++) {
				fleetsEl[i].removeEventListener('touchstart', onTouchStart);
			};
		};

		function listenToLearnMoreClick() {
			var elem = document.getElementById("learn-more");
			elem.addEventListener('touchstart', clickedLearnMore);
		};

		function listenToExitFleets() {
			var exitEl = document.getElementById('exit-fleets');
			exitEl.addEventListener('touchstart', exitFleets);
		};
	}]);
